import {
  ClickAwayListener,
  createStyles,
  makeStyles,
  Popper,
  Theme,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import classNames from "classnames";
import useButtonStyles from "@RHCommerceDev/hooks/useButtonStyles";
import { useCookiesWithPermission } from "@RHCommerceDev/hooks/useCookiesWithPermission";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { useFetchModel } from "@RHCommerceDev/hooks/useFetchModel";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import useTypographyStyles from "@RHCommerceDev/hooks/useTypographyStyles";
import RHCheckmarkIcon from "@RHCommerceDev/icon-checkmark";
import GridColumnIcon from "@RHCommerceDev/icon-grid-column";
import { template } from "lodash";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  ONE_COLUMN_VIEW,
  SELECTED_SORT_VALUE,
  THREE_COLUMN_VIEW,
  TWO_COLUMN_VIEW
} from "@RHCommerceDev/resources/rhr-product-gallery-resource.json";
import yn from "yn";
import { getReqContext } from "@RHCommerceDev/utils/reqContext";
import { getSelectorsByUserAgent } from "react-device-detect";
import TailwindPopper from "@RHCommerceDev/component-tailwind-popper";
import { removeNttSearchParams } from "@RHCommerceDev/utils/formatSearchUrl";
// Note: Avoid prefixing custom-providers/LocationProvider with @RHCommerceDev to ensure content availability on the concierge platform.
import { usePageContentNoProxy } from "custom-providers/LocationProvider";
import RHArrowIcon from "icons/RHArrowIcon";
import { useParams as useParams1 } from "@RHCommerceDev/hooks/useParams";

const DEFAULT_GRID_COLUMNS = 6;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    arrow: {
      // fontSize: 14,
      color: "#808080",
      "&.active": {
        transform: "rotate(180deg)"
      }
    },
    gridIcon: {
      color: "#C4C4C4",
      "&:hover": {
        color: "black"
      },
      "&[data-active='true']": {
        color: "black"
      }
    }
  })
);

export interface GridControllerProps {
  gridColumns: number;
  sortMenu?: SearchResultSortOption[];
  setGridColumns?: (gridColumns: number) => void;
  isCG?: boolean;
  categoryId?: string;
  hide3Column?: boolean;
  hide2Column?: boolean;
  hide1Column?: boolean;
  ssr?: boolean;
  facetlength?: number;
  activeTab?: TabInfo;
  sortingLabel?: string;
  setSortingLabel?: (label: string) => void;
  country?: string;
}

const GridController: FC<GridControllerProps> = ({
  gridColumns,
  sortMenu,
  setGridColumns,
  isCG = false,
  categoryId,
  hide3Column = false,
  hide2Column = false,
  hide1Column = false,
  ssr = false,
  facetlength,
  activeTab,
  sortingLabel,
  setSortingLabel,
  country
}) => {
  const env = useEnv();
  const classes = useStyles();
  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const between = useMediaQuery<Theme>(theme =>
    theme.breakpoints.between(992, 1201)
  );
  const { sortBy } = useParams<{ sortBy: string }>();
  const params = useParams1({
    maxnrpp: "",
    loadAll: "",
    fromCG: ""
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const { pathname } = useLocation();
  const [currentSort, setCurrentSort] = useState(null);
  const isAemPage = !pathname?.includes(".jsp");
  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
  const FEATURE_TAILWIND_ICON = yn(env.FEATURE_TAILWIND_ICON);
  const DefaulPopper = FEATURE_TAILWIND_COMPONENTS ? TailwindPopper : Popper;
  const buttonStyles = useButtonStyles({
    keys: ["unstyledBtn"]
  });
  const { pageContent } = !isAemPage
    ? usePageContentNoProxy()
    : useFetchModel("/admin/products", false, false);

  const LAST_SORTMENU = "lastSortMenu";
  const { setCookieWrapper } = useCookiesWithPermission();
  const req = getReqContext();
  let mobile = false;
  const userAgent = req && req?.headers["user-agent"];
  if (userAgent) {
    const { isMobile } = getSelectorsByUserAgent(userAgent);
    mobile = isMobile;
  }

  const getSortingNavStateFromLabel = (sortingLabel: string) => {
    if (sortingLabel && sortBy) {
      const matchedItem = sortMenu?.find(item => item?.label === sortingLabel);
      if (matchedItem && location.search) {
        const currentParams = new URLSearchParams(location.search);
        const newParams = new URLSearchParams(matchedItem.navigationState);
        newParams.forEach((value, key) => {
          currentParams.set(key, value);
        });

        history.push({
          pathname: pathname,
          search: currentParams.toString()
        });
      }
    }
  };

  useEffect(() => {
    if (sortBy) {
      getSortingNavStateFromLabel(sortingLabel!);
    }
  }, [country]);

  const handleSortClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const applySort = useCallback(
    menu => {
      let navState = menu.navigationState;
      const urlParams = new URLSearchParams(navState);

      if (pathname?.includes("/catalog/category/products.jsp")) {
        setCurrentSort(menu.label);
        setSortingLabel?.(menu?.label!);

        navState = navState.replace(/Ntt=undefined&/, "");
        // navState = navState.replace("Ntt", PG_SEARCH_TERM);
        const searchParams = new URLSearchParams(navState);

        if (categoryId) {
          searchParams.set("categoryId", categoryId);
        }
        navState = searchParams.toString();
      }

      if (
        activeTab &&
        yn(env.FEATURE_SEARCH_ALL_BRANDS) &&
        pathname?.includes("/search/")
      ) {
        const searchParams = new URLSearchParams(navState);
        //@ts-ignore
        searchParams.set("site", activeTab?.site);
        //@ts-ignore
        searchParams.set("activeTab", activeTab?.tabNo);
        navState = searchParams.toString();
      }

      sessionStorage.setItem(LAST_SORTMENU, urlParams?.get("Ns") || "");

      setAnchorEl(null);
      navState = removeNttSearchParams(navState);

      if (params?.loadAll! && params?.maxnrpp!) {
        navState = `${navState}&loadAll=${params?.loadAll}&maxnrpp=${params?.maxnrpp}`;
      }

      if (params?.fromCG === "true") {
        navState += "&fromCG=true";
      }

      history.replace({ search: navState });
    },
    [pathname, activeTab, env.FEATURE_SEARCH_ALL_BRANDS, history, categoryId]
  );

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseCaption", "rhBaseBody2"]
  });

  /*
    this is a hack to make this re render several times
  */
  const key = ssr ? Date.now() : 1;

  const handleGridColumnIconV3 = useCallback(() => {
    setGridColumns?.(4);
  }, []);

  const handleGridColumnIconV2 = useCallback(() => {
    setGridColumns?.(6);
  }, []);

  const handleGridColumnIconV1 = useCallback(() => {
    setGridColumns?.(12);
  }, []);

  return (
    <div
      className={isCG ? "h-full" : ""}
      style={{
        flex: "none",
        display: "flex",
        alignItems: "center"
      }}
    >
      {processEnvServer && mobile ? (
        <></>
      ) : (
        (mdUp ||
          (mdUp && between && facetlength! <= 6) ||
          (processEnvServer && facetlength! >= 6)) &&
        sortMenu &&
        sortMenu.length > 0 && (
          <>
            <button
              className={classNames([
                buttonStyles.unstyledBtn,
                "flex items-center"
              ])}
              role="combobox"
              onClick={handleSortClick}
              aria-expanded={Boolean(anchorEl)}
              aria-label={template(
                pageContent?.Selected_Sort_Value || SELECTED_SORT_VALUE
              )({
                optionSortValue:
                  currentSort ?? sortMenu?.find(s => s.selected)?.label
              })}
            >
              <Typography
                className={classNames([
                  typographyStyles.rhBaseCaption,
                  "cursor-pointer !flex items-center select-none !uppercase mr-[28px] sm:!mr-1 md:!mr-0"
                ])}
              >
                {pageContent?.SORT}:
              </Typography>
              <span
                className={typographyStyles.rhBaseCaption}
                style={{
                  marginLeft: 7,
                  textTransform: "uppercase",
                  cursor: "pointer"
                }}
              >
                {sortMenu?.find(s => s.selected)?.label}
              </span>
              {!FEATURE_TAILWIND_ICON ? (
                <KeyboardArrowDown
                  className={`${classes.arrow} ${
                    Boolean(anchorEl) && "active"
                  }`}
                  style={{ fontSize: 13, marginLeft: 3 }}
                  aria-label={pageContent?.Open_Sort_Menu ?? ""}
                  aria-haspopup="true"
                  aria-expanded={Boolean(anchorEl)}
                />
              ) : (
                <RHArrowIcon
                  className={`${classes.arrow} rotate-90 ${
                    Boolean(anchorEl) && "active"
                  }`}
                  style={{ fontSize: 13, marginLeft: 3 }}
                  aria-label={pageContent?.Open_Sort_Menu ?? ""}
                  aria-haspopup="true"
                  aria-expanded={Boolean(anchorEl)}
                />
              )}
            </button>

            <DefaulPopper
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              placement={"bottom-end"}
              aria-expanded={Boolean(anchorEl)}
              modifiers={{
                flip: {
                  enabled: false
                },
                preventOverflow: {
                  enabled: false
                }
              }}
              style={{ zIndex: 9999 }}
              disablePortal={true}
            >
              <ClickAwayListener
                onClickAway={() => {
                  setAnchorEl(null);
                }}
              >
                <div
                  className={
                    "p-[17px_22px] bg-[#f9f7f4] shadow-[0px_1px_4px_rgba(0,0,0,0.25)] w-[270px] mt-[-15px] ml-[-143px]"
                  }
                  tabIndex={0}
                >
                  <button
                    className={classNames([
                      buttonStyles.unstyledBtn,
                      "w-full capitalize mr-3",
                      "flex justify-between mr-0 mb-[17px] items-center !uppercase"
                    ])}
                    onClick={() => setAnchorEl(null)}
                    aria-label={pageContent?.Close_Sort_Menu ?? ""}
                  >
                    <Typography
                      className={classNames([typographyStyles.rhBaseBody2])}
                      style={{
                        textTransform: "uppercase"
                      }}
                    >
                      {pageContent?.SORT}:
                    </Typography>
                    {!FEATURE_TAILWIND_ICON ? (
                      <KeyboardArrowUp
                        className={classes.arrow}
                        style={{ fontSize: 14 }}
                      />
                    ) : (
                      <RHArrowIcon
                        className={classes.arrow}
                        style={{ fontSize: 14, transform: "rotate(270deg)" }}
                      />
                    )}
                  </button>
                  {sortMenu.map((menu, index) => (
                    <div
                      className={
                        "flex items-center cursor-pointer mb-[10px] mt-[10px]"
                      }
                      key={`sort-${index}`}
                      role="none"
                    >
                      <button
                        className={buttonStyles.unstyledBtn}
                        aria-selected={menu.selected}
                        aria-label={`${
                          pageContent?.Sort_Option || menu.selected
                            ? "selected"
                            : "unselected"
                        } ${menu.label}`}
                        onClick={() => applySort(menu)}
                      >
                        <Typography
                          key={`sortOption-${index}`}
                          className={classNames([
                            typographyStyles.rhBaseBody2,
                            "w-full !capitalize !mr-3"
                          ])}
                        >
                          {menu.label}
                        </Typography>
                      </button>
                      <div aria-hidden="true">
                        {menu.selected && (
                          <RHCheckmarkIcon style={{ fontSize: 15 }} />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </ClickAwayListener>
            </DefaulPopper>
          </>
        )
      )}

      {!hide3Column && smUp && (
        <button
          className={buttonStyles.unstyledBtn}
          style={{ padding: 0, marginLeft: 30 }}
          onClick={handleGridColumnIconV3}
          aria-label={
            (pageContent?.Three_Column_View ?? THREE_COLUMN_VIEW) +
            (gridColumns === 4 ? " selected" : " unselected")
          }
          key={key + gridColumns + 3}
        >
          <GridColumnIcon
            key={key + gridColumns + 3}
            className={classNames([
              classes.gridIcon,
              "h-[14px] w-[14px] cursor-pointer p-0 !transition-all !ease-in-out !duration-500"
            ])}
            column={3}
            data-active={gridColumns === 4}
          />
        </button>
      )}
      {!hide2Column && (
        <button
          className={buttonStyles.unstyledBtn}
          style={{ padding: 0, marginLeft: 30 }}
          onClick={handleGridColumnIconV2}
          aria-label={
            (pageContent?.Two_Column_View ?? TWO_COLUMN_VIEW) +
            (gridColumns === 6 ? " selected" : " unselected")
          }
          key={key + gridColumns + 2}
        >
          <GridColumnIcon
            key={key + gridColumns + 2}
            className={classNames([
              classes.gridIcon,
              "h-[14px] w-[14px] cursor-pointer p-0 !transition-all !ease-in-out !duration-500"
            ])}
            column={2}
            data-active={gridColumns === 6}
          />
        </button>
      )}
      {!hide1Column && (
        <button
          className={buttonStyles.unstyledBtn}
          style={{ padding: 0, marginLeft: 30 }}
          onClick={handleGridColumnIconV1}
          aria-label={
            pageContent?.One_Column_View ??
            ONE_COLUMN_VIEW + (gridColumns === 12 ? " selected" : " unselected")
          }
          key={key + gridColumns + 1}
        >
          <GridColumnIcon
            key={key + gridColumns + 1}
            className={classNames([
              classes.gridIcon,
              "h-[14px] w-[14px] cursor-pointer p-0 !transition-all !ease-in-out !duration-500"
            ])}
            column={1}
            data-active={gridColumns === 12}
          />
        </button>
      )}
    </div>
  );
};

GridController.defaultProps = {
  gridColumns: DEFAULT_GRID_COLUMNS,
  sortMenu: []
};

export default GridController;
